import React from 'react';
import Helmet from 'react-helmet';

import HeaderComponent from '../components/HeaderComponent';
import Layout from '../components/layout';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import CoachingWhatsIncluded from '../components/CoachingWhatsIncluded';
import HowItWorks from '../components/HowItWorks';
import WhatToExpect from '../components/WhatToExpect';
import CoachingGetStarted from '../components/CoachingGetStarted';
import GetToKnow from '../components/GetToKnow';
import FAQs from '../components/FAQs';
import coachingHero from '../assets/images/coaching-hero.jpg';

const Coaching = () => {
  const siteTitle = 'Individual and Group Coaching | Experiment With Wonder';
  
  return (
    <Layout>
      <Helmet title={siteTitle}></Helmet>
      <HeaderComponent
        buttonType="coaching"
        backgroundImg={coachingHero}
      >
        <strong>One-on-One and Group Coaching<br /><span className="space">&nbsp;</span>to Explore&nbsp;Life’s&nbsp;Possibilities</strong>
      </HeaderComponent>
      <Testimonials />
      <CoachingWhatsIncluded />
      <HowItWorks />
      <WhatToExpect />
      <CoachingGetStarted />
      <GetToKnow />
      <FAQs />
      <Contact />
    </Layout>
  );
}

export default Coaching;